<template>
  <h3>Welcome to www.ikunfans.cn</h3>
  <div style="display: flex; justify-content: center; align-items: center;">
    <img alt="logo" src="./assets/logo.png">
    <img alt="ikun1" src="./assets/ikun1.jpeg">
    <img alt="ikun2" src="./assets/ikun2.jpeg">
    <img alt="ikun3" src="./assets/ikun3.jpeg">
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

img {
  width: 200px;
  height: 200px;
}
</style>
